<template>
    <div class="button_group" style="margin-top: 10px;">
        <router-link class="btn02 bw4" tag="button"  :to="{path:'/leisure/namedsnail', query: {t: new Date().getTime()}}">달팽이</router-link>
        <router-link class="btn02 bw4" tag="button"  :to="{path:'/leisure/namedspeedsadali', query: {t: new Date().getTime()}}">S사다리</router-link>
        <router-link class="btn02 bw4" tag="button"  :to="{path:'/leisure/nameddalidali', query: {t: new Date().getTime()}}">다리다리</router-link>
        <router-link class="btn02 bw4" tag="button"  :to="{path:'/leisure/namedsadali', query: {t: new Date().getTime()}}">사다리</router-link>
    </div>
</template>

<script>
    export default {
        name: "LeisureNamedLinks"
    }
</script>

<style scoped>

</style>